import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, { class: "ion-no-border" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, { "default-href": _ctx.defaultHref }, null, 8, ["default-href"])
            ]),
            _: 1
          }),
          (_ctx.iconFilter)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                slot: "end",
                onClick: _ctx.callbackFilter,
                class: "ion-activatable ripple-parent btn-filter"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_ripple_effect),
                  _createVNode(_component_ion_icon, { icon: _ctx.optionsOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.iconAbout)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 1,
                slot: "end",
                class: "ion-activatable ripple-parent btn-filter",
                onClick: _ctx.handlePopover
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_ripple_effect),
                  _createVNode(_component_ion_icon, { icon: _ctx.ellipsisHorizontal }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.iconNotification)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 2,
                slot: "end",
                class: "ion-activatable ripple-parent btn-filter",
                onClick: _ctx.markAllSeen
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_ripple_effect),
                  _createVNode(_component_ion_icon, { icon: _ctx.eyeSharp }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_title, { class: "page-title" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}