import { Timestamp } from '@/types/interface';

const fireBaseTime = (time: Timestamp): string => {
  if (time) {
    const date = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
    const firebaseTime = date.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
    return firebaseTime;
  } else {
    return '';
  }
};

export { fireBaseTime };
