<template>
  <ion-content class="">
    <div class="heading ion-text-center">
      <h1 class="f-bold title-main black">About DIGITExpo</h1>
    </div>
    <div class="popover-content">
      <p
        class="black title"
        @click="openExternalUrl('https://digit-expo.com/contact/')"
      >
        Contact Digit Team
      </p>
      <p
        class="black title"
        @click="openExternalUrl('https://digit-expo.com/travel/')"
      >
        Travel Information
      </p>
      <p
        class="black title"
        @click="openExternalUrl('https://digit-expo.com/faq/')"
      >
        Frequently Asked Questions
      </p>
      <p
        class="black title"
        @click="openExternalUrl('https://digit-expo.com/terms-conditions/')"
      >
        Terms & Conditions
      </p>
    </div>
  </ion-content>
</template>

<script>
import { IonContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  setup() {
    const openExternalUrl = function (externalUrl) {
      window.open(externalUrl, '_system');
    };

    return {
      openExternalUrl,
    };
  },
});
</script>

<style scoped>
.heading {
  padding: 2rem 0 2rem;
  border-bottom: 1px solid #e6e6e6;
}
.title-main {
  font-size: 1.7rem;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}
.title {
  line-height: 3.4rem;
  padding: 0 2rem;
}
</style>

<style>
.popover-about {
  --width: 30.9rem;
}
</style>
