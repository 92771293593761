
import { defineComponent } from 'vue';
import {
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonHeader,
  IonRippleEffect,
  popoverController,
} from '@ionic/vue';
import {
  arrowBackOutline,
  optionsOutline,
  ellipsisHorizontal,
  eyeSharp,
} from 'ionicons/icons';
import AgendaFilter from './AgendaFilter.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonHeader,
    IonRippleEffect,
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    defaultHref: {
      type: String,
      default: '',
    },
    iconNotification: Boolean,
    iconFilter: Boolean,
    iconAbout: Boolean,
    callbackFilter: {
      type: Function,
      default: () => {
        return;
      },
    },
    markAllSeen: {
      type: Function,
      default: () => {
        return;
      },
    },
  },
  setup() {
    const handlePopover = async (ev: Event) => {
      const popover = await popoverController.create({
        component: AgendaFilter,
        cssClass: 'popover-about',
        event: ev,
        translucent: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    };

    return {
      arrowBackOutline,
      optionsOutline,
      ellipsisHorizontal,
      eyeSharp,
      handlePopover,
    };
  },
});
