
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { fireBaseTime } from '@/helpers/formatDate';
import { Capacitor } from '@capacitor/core';
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonBadge,
  IonSpinner,
} from '@ionic/vue';
import { Notification } from '@/types/interface';
import NotificationService from '@/services/notification.service';
import { PushNotifications } from '@capacitor/push-notifications';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HeaderMain,
    IonCard,
    IonCardContent,
    IonBadge,
    IonSpinner,
  },
  setup() {
    const store = useStore();
    const isReady = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    // const unRead = ref<boolean>(true);

    const handleNotification = async () => {
      const notificationStat =
        await NotificationService.getDelegatePrivateNotification(
          process.env.VUE_APP_EVENT_ID as string,
          store.state.user.uid
        );

      if (notificationStat) {
        store.commit('SET_NOTIFICATION_STAT', notificationStat);
      }

      const notifications = await NotificationService.getNotifications(
        process.env.VUE_APP_EVENT_ID as string
      );

      if (notifications) {
        store.commit('SET_NOTIFICATIONS', notifications);
      }
    };

    const notifications = computed(
      (): Notification[] => store.state.notifications
    );
    const notificationStats = computed(() => store.state.notificationStat);

    const markAllSeen = async () => {
      try {
        let sync = true;
        isLoading.value = true;
        for (const notification of notifications.value) {
          console.log(notificationStats.value);

          if (
            !notificationStats.value ||
            !notificationStats.value.event ||
            !notificationStats.value.event.notification_ids ||
            !notificationStats.value.event.notification_ids.includes(
              notification.id
            )
          ) {
            const res = await NotificationService.readNotification(
              process.env.VUE_APP_EVENT_ID as string,
              notification.id as string
            );
            isLoading.value = false;

            if (res) {
              if (!notificationStats.value) {
                location.reload();
              }
              store.commit('MARK_NOTIFICATION_AS_READ', notification.id);
              isLoading.value = false;
            }

            sync = false;
          }
        }

        if (sync) {
          await NotificationService.syncUnread(
            process.env.VUE_APP_EVENT_ID as string
          );
        }

        if (Capacitor.isNativePlatform()) {
          await PushNotifications.removeAllDeliveredNotifications();
        }
      } catch (error) {
        console.error(error);
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      try {
        await handleNotification();
      } catch (e) {
        console.log('Notification get latest error', e);
      }
    });

    return {
      fireBaseTime,
      markAllSeen,
      isLoading,
      notifications,
      isReady,
      notificationStats,
    };
  },
});
